import React, { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';

import { useCustomStyles } from './Checkbox.style';
import { Icon } from '../Icon';

type BaseInputProps = Pick<
	InputHTMLAttributes<HTMLInputElement>,
	'required' | 'checked' | 'disabled' | 'value' | 'name' | 'readOnly' | 'onChange' | 'id' | 'tabIndex'
>;

export type Props = BaseInputProps & {
	isIndeterminate?: boolean;
	ref?: ForwardedRef<HTMLInputElement>;
};

// Type hack due to mismatch with @mui/material and @types/react https://github.com/mui/material-ui/issues/41906
export const Checkbox: React.ForwardRefExoticComponent<Omit<Props, 'ref'> & React.RefAttributes<HTMLInputElement>> =
	forwardRef<HTMLInputElement, Props>(({ isIndeterminate, ...inputProps }, ref) => {
		const { classes, cx } = useCustomStyles();

		return (
			<div className={classes.container}>
				<input {...inputProps} className={cx(classes.checkbox)} type="checkbox" ref={ref} />
				<span className={classes.checkboxSpan}>
					<Icon type={isIndeterminate ? 'minus' : 'checkmark'} className={cx(classes.icon)} />
				</span>
			</div>
		);
	});

Checkbox.displayName = 'Checkbox';
