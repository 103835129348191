import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles<void, 'checkboxSpan' | 'icon'>()((theme, _, classes) => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		position: 'relative',
		height: theme.checkbox.size,
		width: theme.checkbox.size,
		zIndex: theme.zIndex.default,
	},
	checkboxSpan: {
		width: theme.checkbox.size,
		border: theme.checkbox.border.unselected.default,
		borderRadius: theme.checkbox.borderRadius,
		backgroundColor: theme.checkbox.unselected.background.color.default,
		display: 'flex',
		zIndex: -1,
	},
	icon: {
		display: 'none',
	},
	checkbox: {
		position: 'absolute',
		opacity: 0,
		width: '100%',
		height: '100%',
		cursor: 'pointer',
		// UNCHECKED
		[`&:hover ~ .${classes.checkboxSpan}`]: {
			backgroundColor: theme.checkbox.unselected.background.color.hover,
			border: theme.checkbox.border.unselected.hover,
		},
		[`&:focus ~ .${classes.checkboxSpan}`]: {
			backgroundColor: theme.checkbox.unselected.background.color.focus,
			boxShadow: theme.checkbox.focus,
			border: theme.checkbox.border.unselected.focus,
		},
		[`&:active ~ .${classes.checkboxSpan}`]: {
			backgroundColor: theme.checkbox.unselected.background.color.pressed,
			border: theme.checkbox.border.unselected.pressed,
		},
		// CHECKED
		[`&:checked ~ .${classes.checkboxSpan}`]: {
			borderWidth: '0',
			backgroundColor: theme.checkbox.selected.background.color.default,

			[`.${classes.icon}`]: {
				display: 'block',
				color: theme.checkbox.selected.foreground.color.default,
			},
		},
		[`&:checked:hover ~ .${classes.checkboxSpan}`]: {
			backgroundColor: theme.checkbox.selected.background.color.hover,
		},

		[`&:checked:focus ~ .${classes.checkboxSpan}`]: {
			backgroundColor: theme.checkbox.selected.background.color.focus,
		},
		[`&:checked:active ~ .${classes.checkboxSpan}`]: {
			backgroundColor: theme.checkbox.selected.background.color.pressed,
		},
		// DISABLED UNCHECKED
		'&:disabled': {
			pointerEvents: 'none',
		},
		[`&:disabled ~ .${classes.checkboxSpan}`]: {
			border: theme.checkbox.border.unselected.disabled,
			backgroundColor: theme.checkbox.unselected.background.color.disabled,
		},
		// DISABLED CHECKED
		[`&:disabled:checked ~ .${classes.checkboxSpan}`]: {
			borderWidth: '0',
			backgroundColor: theme.checkbox.selected.background.color.disabled,

			[`.${classes.icon}`]: {
				display: 'block',
				color: theme.checkbox.selected.foreground.color.disabled,
			},
		},
	},
}));
